import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/PackshotStill_Kharisma.jpg"
import { navigate } from "gatsby-link"
const kharisma = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Kharisma." image={image} />
    <div className="result" id="1">
      <h3>Olet tunteiden tulkki. </h3>
      <p>
        Et puhu paljon, mutta silloin kun puhut, muut kuuntelevat. Osaat
        kiteyttää asiat niin kuin ne ovat. Poimit yksityiskohdat ja hahmotat
        niistä kokonaisuuden. Osaat ilmaista itseäsi kirjallisesti kadehdittavan
        hienosti. 
        <br />
        <br />
        Kahvisi on Löfbergs Kharisma. Syvä, luonteikas ja intensiivinen.
      </p>
      <StaticImage
        src="../assets/PackshotStill_Kharisma.jpg"
        alt="Löfbergs Kharisma"
      />

      <Buttons
        quote={"Kahvini on Löfbergs Fair Dark Roast."}
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default kharisma
